<template>
  <div>
    <h2>Tabulasi Data {{ $store.state.moduleKumham.module }}</h2>
    <b-row>
      <b-col class="m-3">
        <label for="search"> Cari nomor HP:</label>
        <b-form-input
          v-model="filter"
          type="search"
          placeholder="Search"
        ></b-form-input>
      </b-col>
      <b-col class="m-3">
        <label> Tambah Order Id:</label>
        <b-row>
          <b-col>
            <b-form-input v-model="orderId" placeholder="9999"></b-form-input>
          </b-col>
          <b-col>
            <button @click="addOrderId()" class="btn btn-primary">Tambah</button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-table
      striped
      hover
      :filter="filter"
      :items="$store.state.moduleKumham.orderingResult"
      :fields="fields"
    >
      <template v-slot:cell(actions)="data">
        <b-table hover :items="data.item.Orders" :fields="orderFields">
          <template v-slot:cell(date)="data">
            {{ formatDate(data.item.Date) }}
          </template>
          <template v-slot:cell(sum)="data">
            {{ formatCurr(data.item.total) }}
          </template>
          <template v-slot:cell(actions)="data">
            <b-table hover :items="data.item.OrderItems" :fields="itemFields">
              <template v-slot:cell(price)="data">
                {{ formatCurr(data.item.PricePerQuantity) }}
              </template>
              <template v-slot:cell(total)="data">
                {{ formatCurr(data.item.Total) }}
              </template>
            </b-table>
          </template>
        </b-table>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      filter: "",
      orderId: "",
      fields: [
        {
          key: "PhoneNumber",
          label: "Nomor HP",
          sortable: true,
          // Variant applies to the whole column, including the header and footer
          // variant: "danger",
        },
        {
          key: "actions",
          label: "Orders",
          sortable: false,
        },
      ],
      orderFields: [
        {
          key: "OrderId",
          label: "Nomor Order",
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "sum",
          label: "Total",
          sortable: true,
        },
        {
          key: "actions",
          label: "Items",
          sortable: false,
        },
      ],
      itemFields: [
        {
          key: "Type",
          label: "Type",
          sortable: true,
        },
        {
          key: "SubType",
          label: "SubType",
          sortable: true,
        },
        {
          key: "Name",
          label: "Label",
          sortable: true,
        },
        {
          key: "Description",
          label: "Desc",
          sortable: true,
        },
        {
          key: "Quantity",
          label: "Qty",
          sortable: false,
        },
        {
          key: "Identifier",
          label: "Satuan",
          sortable: false,
        },
        {
          key: "price",
          label: "Harga",
          sortable: false,
        },
        {
          key: "total",
          label: "SubTotal",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("fetchKumham");
  },
  methods: {
    print(val) {
      console.log(val);
    },
    addOrderId() {
      this.$store.dispatch("addOrderId", this.orderId);
      this.orderId = ""
    },
    formatDate(date) {
      return moment(date).format("ll");
    },
    formatCurr(val) {
      return parseInt(val).toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
      });
    },
  },
};
</script>

<style>
</style>